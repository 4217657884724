<template>
  <v-card class="upload-card fill-height">
    <v-card-title class="mb-4"><h3 class="title">Upload</h3></v-card-title>
    <v-layout>
      <v-flex align-start justify-start>
        <vue-dropzone
          ref="dropzone"
          id="dropzone"
          :useCustomSlot="true"
          @vdropzone-processing="onSending"
          @vdropzone-success="onUpload"
          @vdropzone-error="onError"
          :options="dropzoneOptions"
        >
          <div style="height: 100%" class="dropzone-custom-content">
            <v-layout justify-center align-center column>
              <v-progress-circular
                v-if="isUploading"
                class="mb-3"
                :size="70"
                :width="7"
                color="accent"
                indeterminate
              />
              <template v-else>
                <v-icon color="grey lighten-2" class="mx-2" size="80"
                  >add_circle_outline</v-icon
                >
                <div class="grey--text lighten-2 subtitle">
                  In dieses Feld ziehen oder hier klicken...
                </div>
              </template>
            </v-layout>
          </div>
        </vue-dropzone>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import { getAuthHeader } from '@/api/keycloakAuth'

export default {
  name: 'UploadDocument',
  components: {
    vueDropzone: vue2Dropzone,
  },
  data: function () {
    return {
      isUploading: false,
      dropzoneOptions: {
        url: '/api/extractions/uploadFile',
        thumbnailWidth: 0,
        acceptedFiles: 'application/pdf',
      },
    }
  },
  methods: {
    onSending() {
      this.isUploading = true
      this.$refs.dropzone.setOption('headers', getAuthHeader())
    },
    onUpload(file, response) {
      file.previewElement.parentNode.removeChild(file.previewElement)

      this.$emit('fileUploaded', file)
      this.isUploading = false
    },
    onError(file, response) {
      this.$emit('fileUploadFailed', file)
      this.isUploading = false
    },
  },
}
</script>
<style lang="scss">
.upload-card {
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

div[class^='dz-preview'] {
  display: none;
}

.vue-dropzone {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;
  height: 100%;
}
</style>

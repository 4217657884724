<template>
  <v-card class="pb-4 pr-2 fill-height">
    <v-card-title class="mb-4"
      ><h3 class="title">Dokumente im Eingang</h3></v-card-title
    >
    <v-layout row v-if="stats">
      <v-flex class="pt-5" xs6>
        <v-responsive>
          <div class="position-relative">
            <extraction-stats
              :chart-data="statsGraphData"
              :height="200"
              :width="200"
              v-if="statsGraphData"
            />
          </div>
        </v-responsive>
      </v-flex>
      <v-flex xs6>
        <v-list>
          <template v-for="(stat, index) in stats">
            <v-list-tile :key="'statslabel' + index">
              <v-list-tile-action>
                <v-chip :color="colorMap[index]" label />
              </v-list-tile-action>
              <v-list-tile-content>
                {{ statsGraphData.labels[index] }}
              </v-list-tile-content>
              <span>{{ stat.documents - stat.withDueDate }} St.</span>
            </v-list-tile>
            <v-divider :key="'divider' + index" />
          </template>

          <template v-if="sumDocsWithDue > 0">
            <v-list-tile>
              <v-list-tile-action />
              <v-list-tile-content>Wiedervorlage</v-list-tile-content>
              <span>{{ sumDocsWithDue }} St.</span>
            </v-list-tile>
            <v-divider />
          </template>

          <v-list-tile>
            <v-list-tile-action />
            <v-list-tile-content>Gesamt</v-list-tile-content>
            <span>{{ sumDocs }} St.</span>
          </v-list-tile>
        </v-list>
        <v-list></v-list>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { Doughnut, mixins } from 'vue-chartjs'
import documentsApi from '@/api/documentsApi'

const { reactiveProp } = mixins
const extractionStats = {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ['chartData'],

  mounted() {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 60,
      width: 200,
      legend: {
        display: false,
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 'auto',
        },
      },
      tooltips: {
        mode: 'index',
        intersect: true,
        callbacks: {
          label(t, d) {
            const dataset = d.datasets[t.datasetIndex]
            const value = dataset.data[t.index]

            if (t.datasetIndex === 0) {
              return `Dokumente ${d.labels[t.index]}: ${value}`
            } else if (t.datasetIndex === 1) {
              return `Wiedervorlage ${d.labels[t.index]}: ${value}`
            }
          },
        },
      },
    })
  },
}

export default {
  name: 'ExtractionStatsCard',
  components: {
    extractionStats,
  },
  data() {
    return {
      stats: null,
    }
  },
  computed: {
    colorMap() {
      const companies = this.$store.state.companies
      return this.stats.map((s) => {
        const company = companies.find((el) => el.id === s.company_id)
        return company?.main_color || '#bbc3c7'
      })
    },
    statsGraphData() {
      if (this.stats) {
        return {
          labels: this.stats.map((s) => {
            if (s.company_id) {
              return s.name
            }
            return 'Unbekannt'
          }),
          datasets: [
            {
              backgroundColor: this.colorMap,
              data: this.stats.map((s) => s.documents - s.withDueDate),
            },
            {
              backgroundColor: this.colorMap,
              data: this.stats.map((s) => s.withDueDate),
            },
          ],
        }
      }
      return null
    },
    sumDocs() {
      return this.stats.reduce((sum, current) => sum + current.documents, 0)
    },
    sumDocsWithDue() {
      return this.stats.reduce((sum, current) => sum + current.withDueDate, 0)
    },
  },
  methods: {
    async loadData() {
      this.stats = (await documentsApi.getStats()) || []
      this.stats = this.stats.filter((company) => company.documents > 0)

      this.stats.sort((a, b) => (b.company_id || 0) - (a.company_id || 0))
    },
  },
  created() {
    this.loadData()
    this.intervalId = setInterval(() => {
      this.loadData()
    }, 5000)
  },
  destroyed() {
    clearInterval(this.intervalId)
  },
}
</script>
<style scoped></style>

<template>
  <app-layout>
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 md8>
          <extraction-stats-card />
        </v-flex>
        <v-flex xs12 md4>
          <upload-document
            @fileUploaded="fileUploaded"
            @fileUploadFailed="uploadFailed"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex>
          <v-card>
            <v-tabs v-model="activeTab">
              <v-tab>Posteingang</v-tab>
              <v-tab>Wiedervorlage</v-tab>
            </v-tabs>
            <DocumentListing
              :options="{ hasDue, type: 'unarchived' }"
              show-traffic-light
              refresh
              title="Posteingang"
              @selectItem="itemSelected"
            />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </app-layout>
</template>

<script>
import AppLayout from '@/components/AppLayout'
import UploadDocument from '@/pages/dashboard/UploadDocument'
import ExtractionStatsCard from './dashboard/ExtractionStatsCard'
import DocumentListing from '@/pages/extractionView/DocumentListing'

export default {
  name: 'Dashboard',
  components: {
    DocumentListing,
    ExtractionStatsCard,
    UploadDocument,
    AppLayout,
  },
  data() {
    return {
      activeTab: 0,
      latestDocuments: {
        serverData: null,
        pagination: {},
        loading: false,
        headers: [
          { text: 'Datei', sortable: false },
          { text: 'Extrahierte Datensätze', sortable: false },
          { text: 'Typ', sortable: false },
        ],
      },
    }
  },
  computed: {
    dashboardFilter: {
      get() {
        return this.$store.getters.dashboardFilter
      },
      set(newValue) {
        this.$store.dispatch('setDashboardFilter', newValue)
      },
    },
    hasDue() {
      return this.activeTab === 1
    },
  },
  methods: {
    itemSelected(item) {
      const user = this.$store.getters.currentUser
      if (item.locked_by && item.locked_by !== user.id) {
        this.$root.setSnackbar(
          'error',
          `Dieses Dokument wird gerade von Benutzer ${item.locker.username} bearbeitet`
        )
      } else {
        this.$router.push({
          name: 'extractedData',
          params: { id: item.id },
        })
      }
    },
    fileUploaded(file) {
      this.$root.setSnackbar('success', `${file.name} hochgeladen`)
    },
    uploadFailed(file) {
      this.$root.setSnackbar('error', `${file.name} hochgeladen`)
    },

    extractFilenameFromHeader(path) {
      const fileName = path.split(/\//g).pop()
      const [, ...rest] = fileName.split(/-/g)
      return rest.join('-')
    },
  },
  watch: {
    dashboardFilter(filter) {
      if (filter === 'dueDate') {
        this.activeTab = 1
      }
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-card column flat v-if="serverData">
    <v-card-title>
      <h3 class="title">{{ title }}</h3>
    </v-card-title>
    <v-card-text>
      <v-layout>
        <v-flex md2 xs6>
          <v-select :items="filterOptions" v-model="companyId" />
        </v-flex>
        <v-spacer />
        <v-flex mx4 xs6>
          <v-text-field
            :value="search"
            append-icon="search"
            hide-details
            label="Suche (Kommune, Ortst. von, Kassenzeichen)"
            single-line
            @input="debounceSearch"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-flex xs12>
      <v-data-table
        v-if="items"
        v-bind="{ headers, items }"
        :rows-per-page-items="[10]"
        :hide-actions="pagination.totalItems === 0"
        :total-items="this.serverData.total"
        :pagination.sync="pagination"
      >
        <tr
          @click="$emit('selectItem', props.item)"
          slot="items"
          slot-scope="props"
        >
          <td v-if="showTrafficLight">
            <v-avatar :class="documentStatus(props.item)" size="16" />
          </td>
          <template v-if="props.item.master_data">
            <td>{{ props.item.master_data.company.name }}</td>
            <td>{{ props.item.master_data.municipality }}</td>
            <td>
              <template v-if="props.item.master_data.parent">
                {{ props.item.master_data.parent.municipality }}
              </template>
            </td>
          </template>
          <template v-else>
            <td>Unbekannt</td>
            <td></td>
            <td></td>
          </template>
          <td>{{ props.item.pdf | extractNameFromPdf }}</td>
          <td>
            <template v-if="props.item.locker">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ props.item.locker.username }}
                  </span>
                </template>
                {{ props.item.locker.first_name }}
                {{ props.item.locker.last_name }}
              </v-tooltip>
            </template>
          </td>
          <td class="text-xs-right">
            {{ getDocumentDate(props.item.fields) }}
          </td>
          <td class="text-xs-right" v-if="options.hasDue">
            {{ props.item.dueDate | formatDate }}
            {{ diffToToday(props.item.dueDate) }}
          </td>
          <td class="text-xs-right">
            <slot name="actions" v-bind:item="props.item" />
          </td>
        </tr>
        <template slot="pageText" slot-scope="props">
          Einträge {{ props.pageStart }} bis {{ props.pageStop }} von insgesamt
          {{ props.itemsLength }}
        </template>
      </v-data-table>
    </v-flex>
  </v-card>
</template>

<script>
import documentsApi from '@/api/documentsApi'
import { extractNameFromPdf } from '@/utils/pdfUtils'
import debounce from 'lodash/debounce'
import differenceInDays from 'date-fns/difference_in_calendar_days'
import formatDate from 'date-fns/format'
import isValidDate from 'date-fns/is_valid'

const storageKey = (routeName) => `ingewa.${routeName}.selectedCompany`

export default {
  name: 'DocumentListing',
  props: {
    title: {
      required: true,
      type: String,
    },
    options: {
      type: Object,
      default: () => ({
        hasDue: false,
        type: 'all',
      }),
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    showTrafficLight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      intervalId: null,
      loading: false,
      rowsPerPageItems: [20],
      companyId: '*',
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortBy: 'date',
      },
      currentIndex: 1,
      serverData: null,
    }
  },
  computed: {
    filterOptions() {
      return [
        { value: '*', text: 'Alle' },
        ...this.$store.getters.companies.map((company) => {
          return {
            value: `${company.id}`,
            text: company.name,
          }
        }),
        { value: '', text: 'Unbekannt' },
      ]
    },
    hasItemActions() {
      return Boolean(this.$slots['actions'])
    },
    items() {
      return this.serverData.data
    },
    isSearch() {
      return this.search.length > 3
    },
    validSearchTerm() {
      if (this.isSearch) {
        return this.search
      }
      return ''
    },
    headers() {
      const headers = [
        { text: 'Firma', sortable: false },
        { text: 'Ort/Kommune', value: 'municipality' },
        { text: 'Ortst. von', value: 'parent_municipality' },
        { text: 'Dokument', sortable: false },
        { text: 'Bearbeiter', value: 'locker', sortable: false },
        { text: 'Bescheiddatum', value: 'date', align: 'right' },
      ]
      if (this.showTrafficLight) {
        headers.unshift({ text: '', align: '', sortable: false })
      }
      if (this.options.hasDue) {
        headers.push({
          text: 'Wiedervorlage',
          align: 'right',
          sortable: false,
        })
      }
      headers.push({ text: '', sortable: false })

      return headers
    },
  },
  methods: {
    debounceSearch: debounce(function (value) {
      this.search = value
    }, 200),
    getDocumentDate(documentFields) {
      const date = documentFields.datum
      if (date && date.value !== 'n/a') {
        return date.value
      }
    },
    documentStatus(document) {
      if (this.options.hasDue) {
        const date = new Date(document.dueDate)
        const diff = differenceInDays(date, new Date())
        if (diff <= 3) {
          return 'error'
        }
        if (diff <= 7) {
          return 'warning'
        }
      } else {
        const dateValue = document.fields?.datum?.value
        if (typeof dateValue === 'string') {
          const date = new Date(dateValue.split('.').reverse().join('-'))
          if (isValidDate(date)) {
            const diff = differenceInDays(date, new Date())
            if (diff < -21) {
              return 'error'
            } else if (diff < -14) {
              return 'warning'
            }
          }
        }
      }
      return 'white'
    },
    async loadPage(page) {
      if (!this.loading) {
        try {
          this.loading = true
          let orderDirection = this.pagination.descending ? 'ASC' : 'DESC'
          this.serverData = await documentsApi.getDocuments({
            page,
            companyId: this.companyId,
            withDue: this.options.hasDue,
            orderBy: this.pagination.sortBy,
            orderDirection,
            type: this.options.type,
            search: this.validSearchTerm,
          })

          this.pagination.totalItems = this.serverData.total
          this.pagination.page = this.serverData.current_page
          this.pagination.rowsPerPage = this.serverData.per_page
        } catch (error) {
          if (error.message === 'user is not authenticated') {
            this.$router.replace('/login')
          }
        } finally {
          this.loading = false
        }
      }
    },
    diffToToday(dueDate) {
      const now = new Date()
      const date = new Date(dueDate)
      const diff = differenceInDays(date, now)
      if (diff > 0) {
        return `(noch ${diff} Tage)`
      }
      return `(${-diff} Tage überfällig)`
    },
  },
  watch: {
    'pagination.page': {
      handler: 'loadPage',
      immediate: true,
    },
    'pagination.sortBy': {
      handler: 'loadPage',
      immediate: true,
    },
    'pagination.descending': {
      handler: 'loadPage',
      immediate: true,
    },

    validSearchTerm() {
      this.loadPage(1)
    },
    options: {
      deep: true,
      handler() {
        this.loadPage(1)
      },
    },
    companyId(companyId) {
      this.loadPage(1)
      sessionStorage.setItem(storageKey(this.$route.name), companyId)
    },
  },
  filters: {
    extractNameFromPdf,
    formatDate(date) {
      return formatDate(new Date(date), 'DD.MM.YYYY')
    },
  },
  created() {
    if (sessionStorage.getItem(storageKey(this.$route.name)) === null) {
      sessionStorage.setItem(storageKey(this.$route.name), this.companyId)
    }
    this.companyId = sessionStorage.getItem(storageKey(this.$route.name))
    if (this.refresh) {
      this.intervalId = setInterval(() => {
        this.loadPage(this.pagination.page)
      }, 5000)
    }
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
}
</script>

<style scoped></style>
